<template>
  <div class="dashboard">
    <div class="dashboard__container pb-0" v-if="user">
      <div class="dashboard__container--header pt-2 pb-2 only-xs">
        <div class="flex justify-space-between">
          <span>
          <star-rating :read-only="true" :star-size="30" v-if="user && user.rating" v-model="user.rating"></star-rating>
          </span>


          <i class="fa-solid fa-fire-flame"></i>
          <span>
            <button v-if="!isHot" class="btn btn__outlined btn__small mb-3 mr-3" @click="addHot()">Hot <i class="fa-solid fa-fire-flame ml-2"  style="color: #dbdbdb;"></i></button>
             <button v-if="isHot" class="btn btn__outlined btn__small mb-3 mr-3" @click="removeHot()">Hot <i class="fa-solid fa-fire-flame ml-2" style="color:#E25822;"></i></button>

            <button v-if="!isLead" class="btn btn__outlined btn__small mb-3 mr-3" @click="addLead()">Lead <i class="fa-solid fa-badge-check ml-2"  style="color: #dbdbdb;"></i></button>
             <button v-if="isLead" class="btn btn__outlined btn__small mb-3 mr-3" @click="removeLead()">Lead <i class="fa-solid fa-badge-check ml-2 blueHue"></i></button>

            <button v-if="!isWatchList" class="btn btn__outlined btn__small mb-3 mr-3" @click="addWatch()">Watch <i class="fa-regular fa-star ml-2"></i></button>
             <button v-if="isWatchList" class="btn btn__outlined btn__small mb-3 mr-3" @click="removeWatch()">Watch <i class="fa-solid fa-star warning ml-2"></i></button>
            <button class="btn btn__outlined btn__small mb-3" @click="goBack()"><i class="fas fa-arrow-left"></i></button>
           </span>
         </div>
      </div>
      <div class="dashboard__container--header pt-2 pb-2">
     
          <span class="flex align-center justify-start">
            <span style="position: relative;">
              <img :src="pic" alt="" class="avatar"workerId
              style="margin-right:1rem; height:8rem; width:auto; margin-bottom:0; ">

              <span class="fa-stack fa-2x statusCheck" v-show="userContractorPayProfile && userContractorPayProfile.workerId && userContractorPayProfile.onboardingComplete">
                <i class="fa-solid fa-circle fa-stack-2x" style="color:#00C897;"></i>
                <i class="fa-solid fa-check fa-stack-1x fa-inverse" style="color:white;"></i>
              </span>

              <span class="fa-stack fa-2x statusCheck" v-show="userContractorPayProfile && userContractorPayProfile.workerId && (userContractorPayProfile.onboardingStatus && userContractorPayProfile.onboardingStatus == 'IN_PROGRESS')">
                <i class="fa-solid fa-circle fa-stack-2x" style="color:#f0ad4e;"></i>
                <i class="fa-solid fa-check fa-stack-1x fa-inverse" style="color:white;"></i>
              </span>

              <span class="fa-stack fa-2x statusCheck" v-show="userContractorPayProfile && userContractorPayProfile.workerId && (userContractorPayProfile.onboardingStatus && userContractorPayProfile.onboardingStatus == 'NOT_STARTED')">
                <i class="fa-solid fa-circle fa-stack-2x" style="color:#d9534f;"></i>
                <i class="fa-solid fa-check fa-stack-1x fa-inverse" style="color:white;"></i>
              </span>

              

              <!-- <i class="fas fa-check ml-2" style="color:#00C897"></i> -->
            </span>
            <span class="align-start flex flex-column">
             <span class="flex align-center justify-start">
              <span class="flex flex-column pb-0 pt-0 pr-5">
              <h1 class="pb-0" :class="{ danger: dnr }" v-if="user && user.firstName && user.lastName">{{user.firstName}} {{user.lastName}}
                <span v-if="user.beeline"><i class="fa-duotone fa-bee ml-2" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
              </h1>
              

              <span class="flex flex-row pl-1">
              <InterviewStatus :user="user" class="mr-2" />//
              <ContractorStatus :user="user" class="mr-2 ml-2" />//
              <EmployeeStatus :user="user" class="ml-2" />
              </span>

              <div class="strong" v-if="user.employmentStatus">{{user.employmentStatus}}</div>
              <div class="caption" v-if="user.jumpHireDate">Hire Date: {{user.jumpHireDate | moment("MM/DD/YYYY")}}</div>

              <div class="caption" v-if="user.jumpTerminationDate">Termination Date: {{user.jumpTerminationDate | moment("MM/DD/YYYY")}}</div>

              <div class="caption" v-if="user.positionPreference && user.positionPreference.length >= 1">
                Position Preferences: <span v-for="(item, index) in user.positionPreference">{{item.title}}/ </span>
              </div>

              <div class="caption" v-if="(!user.positionPreference || user.positionPreference.length == 0) && userAssignments && userAssignments.length >= 1 && userAssignments[0] && userAssignments[0].position">
                Position Preferences: {{userAssignments[0].position}}
              </div>

              <div class="caption" v-if="user">Last Login: {{ lastLoginTime }}</div>
              </span>

             <!--  <button class="btn btn__letter" v-if="user.branchOnboard">C</button>
              <button class="btn btn__letter" v-if="user.employeeOnboard">E</button> -->

              <star-rating :read-only="true" :star-size="30" v-if="user && user.rating" v-model="user.rating" class="ml-5 hidden-xs"></star-rating>

          
              <span v-if="user && user.points" class="flex align-center ml-5">
                <span class="points animated-box">
                  {{user.points}}
                </span>
              </span>

            </span>
            <i class="italic" v-if="user.tagline">{{user.tagline}}</i>
            
            </span>

          </span>
         
          <span class="hidden-xs">
        <span class="flex">
          <button v-if="!isHot" class="btn btn__outlined btn__small mb-3 mr-3" @click="addHot()">Hot <i class="fa-solid fa-fire-flame ml-2"  style="color: #dbdbdb;"></i></button>
             <button v-if="isHot" class="btn btn__outlined btn__small mb-3 mr-3" @click="removeHot()">Hot <i class="fa-solid fa-fire-flame ml-2" style="color:#E25822;"></i></button>

          <button v-if="!isLead" class="btn btn__outlined btn__small mb-3 mr-3" @click="addLead()">Lead <i class="fa-solid fa-badge-check ml-2" style="color: #dbdbdb;"></i></button>
           <button v-if="isLead" class="btn btn__outlined btn__small mb-3 mr-3" @click="removeLead()">Lead <i class="fa-solid fa-badge-check ml-2 blueHue"></i></button>

          <button v-if="!isWatchList" class="btn btn__outlined btn__small mb-3 mr-3" @click="addWatch()">Watch <i class="fa-regular fa-star ml-2"></i></button>
           <button v-if="isWatchList" class="btn btn__outlined btn__small mb-3 mr-3" @click="removeWatch()">Watch <i class="fa-solid fa-star warning ml-2"></i></button>
          <button class="btn btn__outlined btn__small mb-3 hidden-xs" @click="goBack()"><i class="fas fa-arrow-left"></i></button>
          
          <!-- <Rewards v-if="user" :user="user" /> -->
        </span>
        </span>

      </div>
      <span class="flex align-start flex-wrap">
          <router-link :to="`/users/${$route.params.id}/details`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isDetails, 'btn__outlined btn__small': !isDetails }">User Details</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/employeeonboarding`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isUserEmployeeOnboarding, 'btn__outlined btn__small': !isUserEmployeeOnboarding }">Employee Details</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/pipeline`">
            <button class="btn btn__small  mt-3 mr-3" v-bind:class="{ 'btn__dark': isPipeline, 'btn__outlined btn__small': !isPipeline }">Pipeline 
                <i class="fa-regular fa-filter ml-2" v-if="userInfo && (!userInfo.pipeline || userInfo.pipeline.length == 0)"></i>
                <i class="fa-solid fa-filter ml-2" style="color:#1F966F;" v-if="userInfo && userInfo.pipeline && userInfo.pipeline.length >= 1"></i>
              </button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/certs`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isCerts, 'btn__outlined btn__small': !isCerts }">Certs</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/groups`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isGroups, 'btn__outlined btn__small': !isGroups }">Groups</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/id`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isId, 'btn__outlined btn__small': !isId }">User ID</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/skills`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isSkills, 'btn__outlined btn__small': !isSkills }">Skills
              <span v-if="user.skills" class="ml-2"> ({{user.skills.length}})</span>
            </button>
          </router-link>
          <!-- <router-link :to="`/users/${$route.params.id}/payroll`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isPayroll, 'btn__outlined btn__small': !isPayroll }">Payroll</button>
          </router-link> -->
          <router-link :to="`/users/${$route.params.id}/assignments`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isAssignments, 'btn__outlined btn__small': !isAssignments }">Assignments</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/applications`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isApplications, 'btn__outlined btn__small': !isApplications }">Applications</button>
          </router-link>
          <!-- <router-link :to="`/users/${$route.params.id}/notes`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isNotes, 'btn__outlined btn__small': !isNotes }">Notes</button>
          </router-link> -->
          <!-- <router-link :to="`/users/${$route.params.id}/reviews`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isReviews, 'btn__outlined btn__small': !isReviews }">Ratings & Reviews</button>
          </router-link> -->
          <router-link :to="`/users/${$route.params.id}/gallery`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isGallery, 'btn__outlined btn__small': !isGallery }">Gallery</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/messages`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isMessages, 'btn__outlined btn__small': !isMessages }">messages</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/emergency`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isEmergency, 'btn__outlined btn__small': !isEmergency }">Emergency Contacts</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/payhistory`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isPayHistory, 'btn__outlined btn__small': !isPayHistory }">Pay History</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/timesheets`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isTimesheets, 'btn__outlined btn__small': !isTimesheets }">Timesheets</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/backgroundcheck`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isBackgroundCheck, 'btn__outlined btn__small': !isBackgroundCheck }">Background Checks</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/interview`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isInterview, 'btn__outlined btn__small': !isInterview }">Interview</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/i9`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isIdUpload, 'btn__outlined btn__small': !isIdUpload }">I9 Id Upload</button>
          </router-link>

          
          <router-link :to="`/users/${$route.params.id}/letter`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isOfferLetter, 'btn__outlined btn__small': !isOfferLetter }">Offer Letter</button>
          </router-link>

          <router-link :to="`/users/${$route.params.id}/activity`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isActivity, 'btn__outlined btn__small': !isActivity }">User Activity</button>
          </router-link>


          
          <!-- <router-link :to="`/users/${$route.params.id}/everee`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isEveree, 'btn__outlined btn__small': !isEveree }">Everee Data</button>
          </router-link> -->

          <a :href="user.picture" v-if="user.picture" target="_blank">
            <button class="btn btn__small btn__outlined mr-3 mt-3">Resumé</button>
          </a>

          <v-select
            style="min-width: 180px;"
            class="mr-3 mt-3"
            label="name" 
            :options="mgrs"
            v-model="sendTo"
            @input="updateStatus()"
            :clearable="true"
            placeholder="send profile"
            >
          </v-select>

          <button class="btn btn__small btn__primary mr-3 mt-3" v-if="sendTo" @click="sendUser()">Send User 
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
            <i class="fa-solid fa-paper-plane ml-3" v-if="!performingRequest"></i>
          </button>


          
          <!-- <button class="btn btn__dark mr-3" @click="deleteUser()">Delete</button> -->
        </span>
    </div>
    <router-view :key="$route.params.id" :user="user" :dnr="dnr"  />
    <Loader v-if="(!user || !user.id)" />
  </div>
</template>

<style scoped>
  .statusCheck {
    position: absolute;
    bottom: 0.25rem;
    right: 0.25rem;
    font-size: 1.2rem;
  }
</style>

<script>
import { mapState } from 'vuex'
import StarRating from 'vue-star-rating'
import Loader from '@/components/Loader.vue'
import EmployeeStatus from '@/components/EmployeeStatus.vue'
import InterviewStatus from '@/components/InterviewStatus.vue'
import ContractorStatus from '@/components/ContractorStatus.vue'
import Rewards from '@/components/Rewards.vue'
import router from '@/router'
import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'userHome',
  data: () => ({
    sendTo: null,
    performingRequest: false,
    lastLoginTime: '',
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userInfo', 'userContractorPayProfile', 'mgrs', 'userAssignments']),
    user() {
      return this.userInfo
    },
    dnr() {
     if (this.user && this.user.blacklist && this.user.blacklist.length >= 1) {
      return true
     }
    },
    isWatchList() {
      if (this.userProfile && this.userProfile.userWatchList && this.userProfile.userWatchList.includes(this.userInfo.id)) {
        return true
      } else {
        return false
      }
    },
    isLead() {
      if (this.user && this.user.lead) {
        return true
      } else {
        return false
      }
    },
    isHot() {
      if (this.user && this.user.hot) {
        return true
      } else {
        return false
      }
    },
    isDetails() {
      return this.$route.name == 'userDetails';
    },
    isCerts() {
      return this.$route.name == 'userCerts';
    },
    isId() {
      return this.$route.name == 'userId';
    },
    isPayroll() {
      return this.$route.name == 'userPayroll';
    },
    isAssignments() {
      return this.$route.name == 'userAssignments';
    },
    isApplications() {
      return this.$route.name == 'userApplications';
    },
    isNotes() {
      return this.$route.name == 'userNotes';
    },
    isReviews() {
      return this.$route.name == 'userReviews';
    },
    isMessages() {
      return this.$route.name == 'userMessages';
    },
    isGallery() {
      return this.$route.name == 'userGallery';
    },
    isGroups() {
      return this.$route.name == 'userGroups';
    },
    isEmergency() {
      return this.$route.name == 'userEmergency';
    },
    isSkills() {
      return this.$route.name == 'userSkills';
    },
    isEveree() {
      return this.$route.name == 'userEveree';
    },
    isPipeline() {
      return this.$route.name == 'userPipeline';
    },
    isPayHistory() {
      return this.$route.name == 'userPayHistory';
    },
    isTimesheets() {
      return this.$route.name == 'userTimesheets';
    },
    isInterview() {
      return this.$route.name == 'userInterview';
    },
    isOfferLetter() {
      return this.$route.name == 'offerLetter';
    },
    isIdUpload() {
      return this.$route.name == 'userIdUpload';
    },
    isUserEmployeeOnboarding() {
      return this.$route.name == 'userEmployeeOnboarding';
    },
    isActivity() {
      return this.$route.name == 'userActivity';
    },


    
    isBackgroundCheck() {
      return (this.$route.name == 'userBackgroundCheck' || this.$route.name == 'backgroundCheck');
    },
    pic () {
      if (this.user) {
        return this.user.photoUrl || 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa'
      }
    },
  },
  created () {
    this.$store.dispatch("getUserFromId", this.$route.params.id);
    if (!this.mgrs || this.mgrs.length < 1) {
      this.$store.dispatch("getMgrsState")
    }
  },
  mounted () {
    // this.$store.dispatch('getUserMessages', this.$route.params.id)
    // this.$store.dispatch('getUserPayroll', this.$route.params.id)
    // this.$store.dispatch('getUserAssignments', this.$route.params.id)
    // this.$store.dispatch('getUserEvents', this.$route.params.id)
    this.updateLastLoginTime();
  },
  watch: {
    user: "updateLastLoginTime", // Watch for changes in the user data and call updateLastLoginTime
  },
  methods: {
    updateLastLoginTime() {
      if (this.user && this.user.logins && this.user.logins.length > 0) {
        // Assuming logins is an array of timestamps
        const lastTimestamp = this.user.logins[this.user.logins.length - 1];
        this.lastLoginTime = moment.unix(lastTimestamp).format("MM/DD/YYYY hh:mm:ss a");
      } else {
        this.lastLoginTime = "No login recorded";
      }
    },
    async sendUser() {
      this.performingRequest = true
      await this.$store.dispatch('sendUserToWatch', {
        user: this.$route.params.id,
        mgr: this.sendTo.userId
      })
      setTimeout(() => {
        this.performingRequest = false
        this.sendTo = false
       }, 500)
    },
    async addWatch() {
      await this.$store.dispatch('watchUser', this.$route.params.id)
    },
    async removeWatch() {
      await this.$store.dispatch('unWatchUser', this.$route.params.id)
    },
    async addLead() {
      await fb.usersCollection.doc(this.user.id).update({
        lead: true
      })
    },
    async removeLead() {
      await fb.usersCollection.doc(this.user.id).update({
        lead: false
      })
    },
     async addHot() {
      await fb.usersCollection.doc(this.user.id).update({
        hot: true
      })
    },
    async removeHot() {
      await fb.usersCollection.doc(this.user.id).update({
        hot: false
      })
    },
    goBack() {
      router.go(-1)
    },
    formatDate(q) {
      if(q) {
        console.log(q)
        const postedDate = new Date(q.seconds) * 1000;
        console.log(postedDate)
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
  },
  components: {
    Loader,
    StarRating,
    Rewards,
    EmployeeStatus,
    ContractorStatus,
    InterviewStatus
  },
  beforeDestroy() {
    this.sendTo = null
    delete this.sendTo
    this.performingRequest = null
    delete this.performingRequest
    delete this.dnr
    delete this.isWatchList
    delete this.isLead
    delete this.isHot
    delete this.isDetails
    delete this.isCerts
    delete this.isId
    delete this.isPayroll
    delete this.isAssignments
    delete this.isApplications
    delete this.isNotes
    delete this.isReviews
    delete this.isMessages
    delete this.isGallery
    delete this.isGroups
    delete this.isEmergency
    delete this.isSkills
    delete this.isEveree
    delete this.isPayHistory
    delete this.isTimesheets
    delete this.isPipeline
    delete this.isInterview
    delete this.isOfferLetter
    delete this.isIdUpload
    delete this.isUserEmployeeOnboarding
    delete this.isBackgroundCheck
    delete this.pic
    delete this.sendUser
    delete this.addWatch
    delete this.removeWatch
    delete this.addLead
    delete this.removeLead
    delete this.addHot
    delete this.removeHot
    delete this.goBack
    delete this.formatDate
  },
  // methods: {
  //   deleteUser() {
  //     userInfo
  //   }
  // },
}

</script>